"use client"

import * as React from "react"

import { DMIcon } from "@supernovaio/dm"
import Actionable, {
  type ActionablePropsForRadix,
} from "@supernovaio/dm/src/components/Actionable/Actionable"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconType } from "@supernovaio/icons/types"

import { cva, type VariantProps } from "class-variance-authority"
import { type ActionableRef } from "reshaped/bundle"

const variants = cva("", {
  variants: {
    variant: {
      default: "text-inherit hover:text-primary !underline",
      primary: "text-primary hover:text-primary-highlighted",
      secondary: "text-neutral-faded",
      critical: "text-critical hover:text-critical-highlighted",
      warning: "text-warning hover:text-warning-highlighted",
      positive: "text-positive hover:text-positive-highlighted",
      upgrade: "text-upgrade hover:text-upgrade-highlighted",
    },
    isDisabled: {
      true: "opacity-disabled",
    },
    size: {
      small: "text-body-small",
      medium: "text-body",
    },
    fontWeight: {
      semibold: "font-semibold",
      normal: "font-normal",
    },
  },
  defaultVariants: {
    variant: "default",
    isDisabled: false,
    size: "medium",
    fontWeight: "semibold",
  },
})

export type DMLinkProps = Pick<
  ActionablePropsForRadix,
  | "children"
  | "isExternalLink"
  | "isDisabled"
  | "ariaLabel"
  | "id"
  | "href"
  | "onClick"
> & {
  isUnderline?: boolean
  variant?: VariantProps<typeof variants>["variant"]
  size?: NonNullable<VariantProps<typeof variants>["size"]>
  fontWeight?: NonNullable<VariantProps<typeof variants>["fontWeight"]>
  iconEnd?: IconType
  innerClassName?: string
}

function DMLinkBase(props: DMLinkProps, ref: ActionableRef) {
  const {
    iconEnd,
    children,
    variant,
    isUnderline,
    isDisabled,
    size,
    fontWeight,
    innerClassName,
    ...rest
  } = props

  return (
    <Actionable
      ref={ref}
      className={cn(
        "inline-block max-w-fit rounded focus:ring-2 focus:ring-offset-1",
        "hover:underline",
        { "!underline": isUnderline, "no-underline": !isUnderline },
        variants({ variant, isDisabled, size, fontWeight })
      )}
      isDisabled={isDisabled}
      {...rest}
    >
      <div className={cn("flex items-center gap-2", innerClassName)}>
        {children}
        {iconEnd && <DMIcon svg={iconEnd} />}
      </div>
    </Actionable>
  )
}

const DMLink = React.forwardRef(
  DMLinkBase
) as React.ForwardRefExoticComponent<DMLinkProps>

export { DMLink }
