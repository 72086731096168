import {
  BillingPlanPageRouteDestination,
  BillingPlanRouterType,
  BrandRouteDestination,
} from "../../utils/routing/types"
import { useParams } from "../useParams"
import { usePermissions } from "../usePermissions"

import { useBrandRouteWithDestination } from "./useBrandRouteWithDestination"

export function useSelectPlanRoute(): BillingPlanRouterType | undefined {
  const { wsId, dsId, versionId, brandId } = useParams()
  const { getPermission } = usePermissions(wsId, dsId)

  const selectPlanRoute = useBrandRouteWithDestination(
    wsId,
    dsId,
    versionId,
    brandId,
    BrandRouteDestination.plan
  )

  const upgradePermission = getPermission({
    resource: "workspaces",
    action: "subscription.update",
  })

  if (!upgradePermission.hasPermission || !selectPlanRoute) {
    return undefined
  }

  return `${selectPlanRoute}/${BillingPlanPageRouteDestination.selectPlan}`
}
