export const FIGMA_VARIABLES_PLUGIN_URL =
  "https://www.figma.com/community/plugin/1303357900761384370"

export const MAILTO_ACCOUNTS = "mailto:accounts@supernova.io"

export const CONTACT_SALES = "https://form.typeform.com/to/OEpX7i3P"

export const PRICING_URL = "https://www.supernova.io/pricing"

export const RELEASE_NOTES =
  "https://learn.supernova.io/latest/releases/latest-releases-EhxYhfBn"

export const DEVELOPERS_DOCS = "https://developers.supernova.io"

export const LEARN_DOCS = "https://learn.supernova.io"

export const SLACK_COMMUNITY = "https://community.supernova.io"

export const enrichLinkWithOrigin = (link: string) =>
  `${window.location.origin}${link}`

export const FIGMA_VARIABLE_IMPORT_FAQ =
  "https://learn.supernova.io/latest/design-systems/import-design-system-data/importing-figma-variables/faq-T4yERPk4#section-the-variable-in-figma-has-a-reference-but-it-was-imported-to-supernova-without-the-reference-what-happened-8e"

export const FIGMA_SAMPLE_FILE_URL =
  "https://www.figma.com/file/PzBOTq7to56cuRB0gmQeP6/Sample-Design-System/duplicate"

export const PAGE_DRAFTS_ONBOARDING_RELEASE_NOTES =
  "https://learn.supernova.io/latest/releases/july-2024/documentation-drafts-QucczCaR"

export const APPROVALS_ONBOARDING_RELEASE_NOTES =
  "https://learn.supernova.io/latest/releases/july-2024/approval-workflow-Qzlx0Ubv"
