export function sureOf<T>(entity: T | null | undefined): NonNullable<T> {
  if (entity == null) {
    throw new Error("sureOf(): value not present")
  }

  return entity
}

type NonNullablePart<T> = T extends null ? never : T

export class Maybe<T> {
  private value: T | null | undefined

  constructor(value: T | null | undefined) {
    this.value = value
  }

  static of<NV>(value: NV | null | undefined) {
    return new Maybe<NV>(value)
  }

  // NOTE: this enforces a Maybe with a non-null value there.
  // .getValue() on this method will always return truthy value type-safely
  // it is useful for the occasions when you have a long transformation pipeline,
  // where you start with some default value that will be returned always
  static defaults<D>(defaultValue: NonNullable<D>): Maybe<NonNullable<D>> {
    return new Maybe<NonNullable<D>>(defaultValue)
  }

  map<R>(mapper: (value: NonNullable<T>) => R): Maybe<NonNullablePart<R>> {
    if (this.value != null) {
      return new Maybe<NonNullablePart<R>>(
        mapper(this.value) as NonNullablePart<R>
      )
    }

    return new Maybe<NonNullablePart<R>>(null)
  }

  // NOTE: flatMap() "eats" the incomming maybe and will use the value of the current maybe as default
  // of the maybe returned by the Mapper()
  // you can safely use Maybies in the flatMap() function return value as if it was a direct value
  flatMap<U>(
    mapper: (value: NonNullable<T>) => U
  ): U extends Maybe<infer R> ? Maybe<R> : Maybe<U> {
    type LocalReturn = U extends Maybe<infer R> ? Maybe<R> : Maybe<U>

    if (this.value != null) {
      const result = mapper(this.value)

      if (result instanceof Maybe) {
        return new Maybe(result.getValue(this.value)) as LocalReturn
      }

      return new Maybe<U>(result) as LocalReturn
    }

    return new Maybe<U>(null) as LocalReturn
  }

  getValue(): T | undefined
  getValue<D>(defaultValue: D): NonNullable<T> | D
  getValue<D>(defaultValue?: D): NonNullable<T> | D | undefined {
    return this.value || defaultValue
  }
}

export function firstOf<T>(arrOfT: ArrayLike<T> | null | undefined): Maybe<T> {
  if ((arrOfT || []).length > 0) {
    return new Maybe<T>((arrOfT || [])[0])
  }

  return new Maybe<T>(null)
}

export function lastOf<T>(arrOfT: ArrayLike<T> | null | undefined): Maybe<T> {
  return new Maybe<T>((arrOfT || [])[(arrOfT || []).length - 1])
}

export type ArrayItemType<T extends any[]> = T extends Array<infer U>
  ? U
  : never
