import {
  BrandRouteDestinationType,
  BrandRouteType,
  BrandRouteWithDestinationType,
} from "../../utils/routing/types"

import { useBrandRoute } from "./useBrandRoute"

export function useBrandRouteWithDestination(
  wsId: string | undefined,
  dsId: string | undefined,
  versionId: string | undefined,
  brandId: string | undefined,
  destination: BrandRouteDestinationType
): BrandRouteWithDestinationType | undefined {
  const brandRoute = useBrandRoute(wsId, dsId, versionId, brandId)

  if (!brandRoute) return undefined

  return `${brandRoute}/${destination}`
}

export function brandRouteWithDestination(
  route: BrandRouteType,
  destination: BrandRouteDestinationType
): BrandRouteWithDestinationType {
  return `${route}/${destination}`
}
