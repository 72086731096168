import { useCallback, useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import {
  useQueryClient,
  type InvalidateQueryFilters,
} from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

type QueryIds = {
  dsId: string
  versionId: string
  brandId?: string
}

export const createBrandQueryKey = (ids: QueryIds) => [
  { dsId: ids.dsId },
  "version",
  {
    versionId: ids.versionId,
  },
  "brand",
  {
    brandId: ids.brandId,
  },
]

export const useInvalidateBrand = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: QueryIds, filters?: InvalidateQueryFilters) =>
      queryClient.invalidateQueries({
        ...filters,
        queryKey: createBrandQueryKey(ids),
      }),
    [queryClient]
  )
}

export const useBrand = (
  dsId: string | undefined,
  versionId: string | undefined,
  brandId: string | undefined
) => {
  const dsIdFallback = useId()
  const versionIdFallback = useId()
  const brandIdFallback = useId()

  return useSafeQuery({
    queryKey: createBrandQueryKey({
      dsId: dsId || dsIdFallback,
      versionId: versionId || versionIdFallback,
      brandId: brandId || brandIdFallback,
    }),
    queryFn: async () => {
      if (!dsId || !versionId || !brandId) {
        return null
      }

      const sdk = await getClientSdk()

      return sdk.brands.getBrand({
        designSystemId: dsId,
        versionId,
        brandId,
      })
    },
  })
}
