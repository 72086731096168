"use client"

import { getReadableRoute } from "@supernovaio/cloud/utils/routing/getReadableRoute"
import { supportedDestinations } from "@supernovaio/cloud/utils/routing/getSettingsRoute"
import { Workspace } from "@supernovaio/sdk"

import {
  SettingsRouteDestination,
  type SettingsRouteType,
} from "../../utils/routing/types"
import { useWorkspace } from "../data/useWorkspace"

import { useBrandRoute } from "./useBrandRoute"
import { useRoutingResolution } from "./useRoutingResolution"

const getSettingsFullRoute = (
  brandRoute: string | undefined,
  finalDestination: SettingsRouteDestination
) => (brandRoute ? `${brandRoute}/settings/${finalDestination}` : undefined)

const getSettingsWorkspaceOnlyRoute = (
  workspace: Workspace | undefined | null,
  finalDestination: SettingsRouteDestination
) => {
  let resolvedDestination = finalDestination

  if (!supportedDestinations.workspaceOnly.includes(finalDestination)) {
    resolvedDestination = SettingsRouteDestination.userProfile
  }

  return `${getReadableRoute(workspace)}/settings/${resolvedDestination}`
}

const getSettingsBasicRoute = (finalDestination: SettingsRouteDestination) => {
  let resolvedDestination = finalDestination

  if (!supportedDestinations.basic.includes(finalDestination)) {
    resolvedDestination = SettingsRouteDestination.userProfile
  }

  return `/settings/${resolvedDestination}`
}

export function useSettingsRoute(
  destination: SettingsRouteDestination
): SettingsRouteType | undefined {
  const resolution = useRoutingResolution()
  const brandRoute = useBrandRoute(
    resolution?.wsId,
    resolution?.dsId,
    resolution?.versionId,
    resolution?.brandId
  )
  const { data: workspace } = useWorkspace(resolution?.wsId, false)

  if (!resolution) {
    return undefined
  }

  if (
    resolution.wsId &&
    resolution.dsId &&
    resolution.versionId &&
    resolution.brandId &&
    !resolution.isIpLocked
  ) {
    return getSettingsFullRoute(
      brandRoute,
      destination ?? SettingsRouteDestination.userProfile
    )
  }

  if (
    resolution.wsId &&
    !resolution.isIpLocked &&
    (!resolution.dsId || !resolution.versionId || !resolution.brandId)
  ) {
    return getSettingsWorkspaceOnlyRoute(
      workspace,
      destination ?? SettingsRouteDestination.userProfile
    )
  }

  if (!resolution.wsId || resolution.isIpLocked) {
    return getSettingsBasicRoute(
      destination ?? SettingsRouteDestination.userProfile
    )
  }

  return undefined
}
