import React from "react"

import { cn } from "../../../utils/cn"
import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"
import { VariantClasses } from "../DMButton.types"

import type { ActionableRef } from "reshaped/bundle"

type ButtonWhiteProps = Omit<DMButtonBaseProps, "variant"> & {
  noShadow?: boolean
}

function DMButtonWhiteBase(props: ButtonWhiteProps, ref: ActionableRef) {
  const {
    size = "medium",
    isFullWidth = false,
    isLoading = false,
    isDisabled = false,
    isHighlighted = false,
    noShadow = false,
    children,
    ...rest
  } = props

  const variantClasses: VariantClasses = {
    solid: {
      base: cn("bg-white text-black", {
        "shadow-raised": !noShadow,
      }),
      hover: "hover:text-primary",
      focus: "focus-visible:ring-2",
      highlighted: "text-primary",
    },
  }

  return (
    <Button
      {...rest}
      ref={ref}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant="solid"
      variantStyles={variantClasses.solid}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonWhite = React.forwardRef(
  DMButtonWhiteBase
) as React.ForwardRefExoticComponent<ButtonWhiteProps>

DMButtonWhite.displayName = "DMButtonWhite"

export { DMButtonWhite }
